import React from 'react';
import './Header.css';

interface HeaderProps {
  activeItem: string;
  onMenuChange: (item: string) => void;
}

const Header = ({ activeItem, onMenuChange }: HeaderProps) => {
  const menuItems = ['About', 'Projects', 'Other', 'Blog'];

  return (
    <nav className="header">
      <ul className="menu-list">
        {menuItems.map((item) => (
          <li 
            key={item} 
            className={`menu-item ${activeItem === item ? 'active' : ''}`}
            onClick={() => onMenuChange(item)}
          >
            <a href={`#${item.toLowerCase()}`}>{item}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Header; 