import React from "react";
import './Main.css';
import { useNavigate } from 'react-router-dom';

interface BlogPost {
  title: string;
  url?: string;  // external URL
  markdownPath?: string;  // local markdown path
}


const blogPosts: BlogPost[] = [
  {
    title: "Pattern Recognition: The Uniqueness of Jordan",
    url: "https://jordangtl2025.weebly.com/blog/jean-billas-blog"
  },
  // {
  //   title: "Citadel Talk",
  //   markdownPath: "/blogs/citadel_talk"
  // }
];

const Blog = () => {
  const navigate = useNavigate();
  const handleClick = (post: BlogPost) => {
    if (post.url) {
      window.open(post.url, '_blank');
    } else if (post.markdownPath) {
      navigate(`${post.markdownPath}`);
    }
  };
  return (
    <div className="blog-container">
      <ul className="blog-list">
        {blogPosts.map((post, index) => (
          <li key={index}>
            <a 
              href={post.url || '#'} 
              onClick={(e) => {
                e.preventDefault();
                handleClick(post);
              }}
              className="blog-title"
            >
              {post.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blog;
