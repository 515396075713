import './App.css';
import Main from './pages/Main';
import { Analytics } from '@vercel/analytics/react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';  
import ProjectDetails from './pages/ProjectDetails';
import BlogPage from './pages/BlogPage';
function App() {
  return (
    <BrowserRouter> 
    <div className="App">
      <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/projects/:projectId" element={<ProjectDetails />} />
          <Route path="/blogs/:blogId" element={<BlogPage />} />
      </Routes>

      <Analytics />
    </div>
    </BrowserRouter>
  );
}

export default App;
